import React from "react";
import { Box, Stack, useBreakpointValue, Container } from "@chakra-ui/react";
import Intro from "./Intro";
const Main = () => {
  const padding = useBreakpointValue({ base: "35px", md: "96px" });

  return (
    <Box>
      <Container maxW="48em" py={["20px", "40px", "90px"]} px={["20px"]}>
        <Box paddingBottom={padding}>
          <Intro />
        </Box>
        <Stack spacing="35px">
        </Stack>
      </Container>
    </Box>
  );
};

export default Main;

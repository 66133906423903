import React from "react";
import { Box, Text, Heading, Stack, HStack, Avatar, Spacer, Button, VStack } from "@chakra-ui/react";
import pdf from './StevenSchultzResume.pdf'

const Intro = () => {
  return (
    <Stack spacing="35px">
      <HStack>
        <Box position="relative" top="5px">
          <Heading align="left" color="#507ca1" paddingRight="15px">
            Steven Schultz
          </Heading>
          <Heading fontSize="20px" color="grey">
            Business Management <br></br>
            + Information Systems
          </Heading>
        </Box>
        <Spacer />
        <Button
          as="a"
          href={pdf}
          target="_blank"
          rounded="full"
          fontFamily="Quicksand"
          bg="#ecebe6"
          _hover={{ bg: "#e0dfda" }}
          _active=""
          display={{ base: "none", sm: "flex" }}
          sx={{
            ":focus": {
              shadow: "none",
            },
          }}
          _selected={{ background: "#E2E8F0", borderColor: "#507ca1" }}
        >
          Resume
        </Button>
        <Button
          as="a"
          href="mailto:schultz.steven61@outlook.com"
          rounded="full"
          fontFamily="Quicksand"
          bg="#ecebe6"
          _hover={{ bg: "#e0dfda" }}
          _active=""
          display={{ base: "none", sm: "flex" }}
          sx={{
            ":focus": {
              shadow: "none",
            },
          }}
          _selected={{ background: "#E2E8F0", borderColor: "#507ca1" }}
        >
          Contact
        </Button>
        <VStack paddingTop="20px">
        <Button
          as="a"
          href={pdf}
          target="_blank"
          rounded="full"
          fontFamily="Quicksand"
          bg="#ecebe6"
          _hover={{ bg: "#e0dfda" }}
          _active=""
          display={{ base: "flex", sm: "none" }}
          sx={{
            ":focus": {
              shadow: "none",
            },
          }}
          _selected={{ background: "#E2E8F0", borderColor: "#507ca1" }}
        >
          Resume
        </Button>
        <Button
          as="a"
          href="mailto:schultz.steven61@outlook.com"
          rounded="full"
          fontFamily="Quicksand"
          bg="#ecebe6"
          _hover={{ bg: "#e0dfda" }}
          _active=""
          display={{ base: "flex", sm: "none" }}
          sx={{
            ":focus": {
              shadow: "none",
            },
          }}
          _selected={{ background: "#E2E8F0", borderColor: "#507ca1" }}
        >
          Contact
        </Button>
        </VStack>
      </HStack>
      <Text font="body" fontSize="18px" lineHeight="tall" fontWeight="bold">
        Welcome to my page 👋
        <Text font="body" fontSize="15px" lineHeight="tall" paddingTop="10px" fontWeight="normal" maxW="45em">
        I'm Steven Schultz, a driven and ambitious student at Bowling Green State University, where I'm earning my Bachelor's degree in Business Management with a focus on Information Systems. 
        My goal is to combine my passion for technology and my knowledge of business to drive innovative solutions and bring about positive change in the corporate world. 
        I'm eager to find a company that'll provide me with a launching pad for professional growth and a deeper understanding of the business structure. 
        I am excited about working in a team environment and furthering my leadership, coding, and database management knowledge. I'm keen on using my skills 
        and knowledge to help organizations reach their goals and objectives. I know I'll be a valuable asset to any company and I'm thrilled for this exciting opportunity 
        that's just around the corner!
        </Text>
      </Text>
    </Stack>
  );
};

export default Intro;
